<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="vehicles"
      class="elevation-1"
      item-key="id"
    >
    <template v-slot:[`item.actions`]="{item}">
      <v-icon @click="deleteVehicle(item._id)">mdi-delete</v-icon>
    </template>
    </v-data-table>
    <v-row no-gutters justify="center">
            <div>
              <v-btn @click="dialog = true" color="primary">
                <h1>+</h1>
              </v-btn>
            </div>
          </v-row>
       <vehicle-modal
        v-if="dialog"
        :dialog="dialog"
        @insert-vehicle="insertVehicle"
        @close-dialog="dialog=false">
       </vehicle-modal>    
  </v-container>
</template>

<script>
    import vehicleModal from "../components/vehicleModal"
    import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "vehicles",
  data() {
    return {
        dialog: false,
        vehicles: [],
      headers: [{ text: "Kenteken", value: "licenseNumber" },
      { text: "Naam", value: "name" },
      { text: "Laadvermogen", value: "maxPayload" },
      { text: "Route", value: "defaultRoute" },
      { text: "", value:"actions",align:"end",sortable:false}
      ]
    }
  },
  methods: {
      async insertVehicle(vehicle){
        try { 
          this.dialog= false
          let response = await fetchPOST("insertVehicle",vehicle)          
          if(response.insertedCount===0) throw "insertVehicle"
          this.vehicles.push(response.ops[0])
          successHandler("Voertuig opgeslagen")
        } catch (e) {
          errorHandler(e,"Voertuig niet opgeslagen")
        }        
      },
    async deleteVehicle(_id){
        try { 
          let response = await fetchPOST("deleteVehicle",{_id:_id})
          if(response.deletedCount===0) throw "deleteVehicle"
          let index = this.vehicles.findIndex(vehicle=> vehicle._id ===_id)
          if(index>-1)this.vehicles.splice(index,1)
          successHandler("Voertuig verwijderd")
        } catch (e) {
          errorHandler(e,"Voertuig niet verwijderd")
        }        
      }
  },
  computed: {

  },
  async mounted() {
    this.vehicles = await fetchGET("fetchVehicles",{})
  },
  components: {vehicleModal}
}
</script>

<style>
</style>