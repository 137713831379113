<template>
      <v-dialog :value="dialog" persistent hide-overlay width="500px">
              <v-card>
             <v-text-field
                 label="Kenteken"
                 v-model="vehicle.licenseNumber"
             ></v-text-field>
             <v-text-field
                 label="Naam"
                 v-model="vehicle.name"
             ></v-text-field>
             <v-text-field
             type="number"
                 label="Laadvermogen"
                 v-model="vehicle.maxPayload"
             ></v-text-field>
             <v-select
                 :items="routes"
                 v-model="vehicle.defaultRoute"
                 label="Route"
             ></v-select>
             <v-btn color="primary" @click="insertVehicle">Opslaan</v-btn>
             <v-btn color="primary" @click="closeDialog">Annuleer</v-btn>
              </v-card>
          </v-dialog>
</template>

<script>
export default {
    name:"vehicleModal",
    props: {dialog: Boolean},
    data(){
        return{
            vehicle:{
                licenseNumber: "",
                name: " ",
                maxPayload: 0,
                defaultRoute: ""
                }
        }
    },
    methods:{
insertVehicle(){
    this.$emit("insert-vehicle",this.vehicle)
},
closeDialog(){
    this.$emit("close-dialog")
}
    },
computed:{
    routes(){
        return this.$store.getters.sortedRoutes
    }
}
}
</script>

<style>

</style>